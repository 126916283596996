
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton } from '@ionic/vue';
import { IonModal } from '@ionic/vue';
import { onIonViewWillEnter } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import MoodList from '@/components/MoodList.vue';
import Logout from '@/components/Logout.vue';
import Modal from '@/components/Modal/Add.vue';
import libStore from "@/lib/libStore";
import toastFail from "@/lib/toastFail";
import loader from "@/lib/loader";

export default defineComponent({
  name: 'Tab1',
  components: {
    MoodList,
    Logout,
    Modal,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonPage
  },
  setup () {
    const { store, currentUser, goToLogin, setUserId } = libStore();
    onIonViewWillEnter(() => {
      goToLogin()
      setUserId()
    });

    const { error } = loader()
    const isModalOpen = ref(false);
    const setModalOpen = (state: boolean) => isModalOpen.value = state;

    return {
      isModalOpen,
      setModalOpen,
      currentUser,
      store,
      error,
      mood: ''
    }
  },
  watch: {
    error(val) {
      if (val) {
        toastFail('Request failed, please try again.')
      }
    }
  },
  methods: {
    title(user: { email: string }) {
      return (user)? user.email : null;
    },
    moodSelected(type: string) {
      this.mood = type;
    },
    openModal() {
      this.setModalOpen(true);
    },
    close() {
      this.setModalOpen(false);
    }
  }
});
