<template>
  <ion-list>
    <ion-radio-group :value="mood" @ionChange="change">
      <ion-item>
        <AvatarHappy class="icon-margin" />
        <ion-label> {{textHappy}} </ion-label>
        <ion-radio color="success" slot="end" :value="happy"></ion-radio>
      </ion-item>
      <ion-item>
        <AvatarUnhappy class="icon-margin" />
        <ion-label> {{textSad}} </ion-label>
        <ion-radio color="warning" slot="end" :value="sad"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</template>

<script lang="ts">
import { IonList, IonItem, IonLabel, IonRadioGroup, IonRadio } from '@ionic/vue';
import { defineComponent } from 'vue';
import AvatarHappy from '@/components/Avatar/Happy.vue';
import AvatarUnhappy from '@/components/Avatar/Unhappy.vue';
import Moods from "@/lib/const";

export default defineComponent({
  name: 'MoodList',
  components: {
    AvatarHappy,
    AvatarUnhappy,
    IonList,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonLabel
  },
  setup() {
    return {
      mood: Moods.HAPPY,
      happy: Moods.HAPPY,
      sad: Moods.SAD,
      textHappy: Moods.TEXT_HAPPY,
      textSad: Moods.TEXT_SAD,
    }
  },
  mounted() {
    this.setMood(this.mood);
  },
  methods: {
    setMood(type: string) {
      this.mood = type;
      this.$emit('mood', type)
    },
    change(type: any) {
      this.setMood(type.detail.value);
    }
  }
});
</script>

<style scoped>
.icon-margin {
  margin-right: 10px;
}
</style>