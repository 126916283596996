
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonText, IonTextarea, IonItem } from '@ionic/vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import Moods from "@/lib/const";
import libStore from "@/lib/libStore";
import {MoodAddItem} from "@/interface/Mood/Add";
import { LocalNotifications } from '@capacitor/local-notifications';

export default defineComponent({
  name: 'ModalAdd',
  props: {
    mood: {
      type: String,
      default: Moods.HAPPY
    }
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonButton,
    IonItem,
    IonText,
    IonTextarea,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol
  },
  emits:['close'],
  setup() {
    const { store } = libStore();
    return {
      store,
      content: ''
    }
  },
  computed: {
    payload(): MoodAddItem {
      return {
        name: this.mood,
        extra: this.content
      }
    }
  },
  methods: {
    send() {
      this.store.dispatch('moods/addMood', this.payload)
      if (this.mood === Moods.SAD) {
        this.notify()
      } else {
        this.clearNotification()
      }
      this.close()
    },
    close() {
      this.$emit('close')
    },
    async notify() {
      return  await LocalNotifications.schedule({
        notifications: [
          {
            title: "BankHappiness",
            body: 'Is your mood still Unhappy ?',
            id: 99,
            schedule: { every: 'hour' },
          },
        ],
      });
    },
    clearNotification() {
      LocalNotifications.getPending().then( res => {
        LocalNotifications.cancel(res);
      })
    }
  }
});
