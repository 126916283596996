import { toastController } from '@ionic/vue';

export default async function  (_message: string) {
    const toast = await toastController
        .create({
            message: _message,
            keyboardClose: true,
            position: "top",
            color: 'warning',
            duration: 3000
        })
    return toast.present();
}