
import { IonImg, IonAvatar } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AvatarHappy',
  components: {
    IonImg,
    IonAvatar
  }
});
