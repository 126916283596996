<template>
  <ion-avatar>
    <ion-img src="assets/icon/Unhappy_Emoji.png"></ion-img>
  </ion-avatar>
</template>

<script lang="ts">
import { IonImg, IonAvatar } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AvatarUnhappy',
  components: {
    IonImg,
    IonAvatar
  }
});
</script>