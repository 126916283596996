import { computed, watch } from "vue";
import { useStore } from 'vuex'
import { loadingController } from '@ionic/vue';

export default function () {
    const store = useStore()
    const loading = computed(() => store.getters['moods/loading'])
    const error = computed(() => store.getters['moods/error'])

    let loader = null as any;
    let fakeDismiss = 1;

    watch(loading, async (val) => {
        if (val && loader === null) {
            // since the loading value can change to false while the await function still not done, we create a fake
            // object to handle the dismiss function call
            loader = {dismiss: () => { fakeDismiss++ }}
            loader = await loadingController.create({message: 'Please wait...', duration: 3000})
            await loader.present()
        } else {
            if (loader) {
                // dismiss the loader if loading change to false
                loader.dismiss();
            }
        }
    })

    return {
        loading: loading,
        error: error
    }
}